/* 
----------------------------------------------------------------------------------------------------------------
Job card elements
----------------------------------------------------------------------------------------------------------------
*/


/*** <CardGrid /> ***/

.grid-section {
    display: flex;
    flex-direction: column;
}

.card-grid { 
    /* background-color: yellow; */
    max-width: 1450px;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 20px;
}

.cards {
    /* background-color: brown; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

/*** <JobCard /> ***/
.job-card {
    flex: 0 0 calc(33.33% - 20px);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
 
    max-width: calc(33.33% - 20px);
    width: 100%;
    border-radius: 10px;
    background-color: var(--deepolive);
    padding-bottom: 20px;
    padding-top: 5px;
    margin-bottom: 20px;
    transition: .3s;
    border: 5px solid transparent;
}

.job-card:hover {
    border: 5px solid brown;
}

.card-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-delete-job,
.btn-edit-job {
    font-size: 20px;
    padding: 10px;
    background-color: transparent;
    border: none;
    max-width: 120px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: white;
}

.btn-delete-job:hover,
.btn-edit-job:hover {
    cursor: pointer;
    color: var(--yellow);
    transition: .3s;

}


/*** <CardData /> ***/

.card-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    padding-left: 1rem;
    padding-right: 1rem;

}
.job-description {
    margin-bottom: 15px;
}

.job-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-data:hover {
    cursor: pointer;
}

.card-data h2{
    color: var(--yellow);
    padding: 18px;
 
}

.card-data p {
    padding: 3px;
}

.card-data a {
    color: var(--lightolive)
}


/*** Icons -> Iconify ***/

.fa-rotate-270 {   /* paw icon next to app title <i> */
    /* font-size: 2.75rem; */
    transform: rotate(-15deg);
    margin-right: 0.6rem;
    /* margin-right: 15px; */
 
}

.fa-rotate-90 {
    transform: rotate(15deg);
    margin-left: 0.6rem;
}

/*** media queries ***/

@media screen and (max-width: 1500px) {

    .card-grid { max-width: 1250px; }
}

@media screen and (max-width: 1300px) {

    .card-grid { max-width: 1050px; }
}

@media screen and (max-width: 969px) {

    .grid-section { padding: 20px 0px; }

    .card-data { font-size: 17px; }

    .job-card {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

@media screen and (max-width: 600px) {

    .job-card {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 400px) {

    .card-data h2{
        font-size: 20px;
     
    }
    .card-data p{
        font-size: 15px;
     
    }
}
@media screen and (max-width: 315px) {

    .card-data h2{
        font-size: 20px;
     
    }
    .card-data p{
        font-size: 15px;
        padding: 1px;
     
    }
}