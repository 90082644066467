
/* 
----------------------------------------------------------------------------------------------------------------
form elemets
----------------------------------------------------------------------------------------------------------------
*/

/* elements shared by different pages */

.login-page, 
.signup-page, 
.new-job-page,
.edit-job-page,
.edit-user-page,
.application-page {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* <form> */
.login-form, 
.signup-form, 
.new-job-form,
.edit-job-form,
.edit-user-form,
.application-form {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: var(--primary);
    width: 500px;
    height: 35em;
    position: relative;
    border-radius: 1em;
    box-shadow:  0 8px 32px 0 var(--darkolive-shadow);
    background-size: 100em;    
}

/* <h1> */
form h1 {  /* form title */
    width: 100%;
    height: auto;
    margin: 0;
    padding: 10px;
    color: var(--lightolive);
    font-size: 2rem;
    text-align: center;
}

/* <Link> */
form a { 
    color: var(--yellow);
    font-size: 18px;
}

/* <p> */
form p {
    color: var(--primary);
    font-size: 18px;
}


/* <input> | <select> | <textarea> */
input[type=text],
input[type=password],
select,
textarea
{
 
    background-color: var(--lightolive);
    color: black;
    border-radius: 0.25em;
    padding: 0.75em;
    font-size: 17px;
    outline: none;
    
}

input[type=text]:focus,
input[type=password]:focus,
select:focus,
textarea:focus
{
    border: 3px solid var(--crimson);

}
  
input[type=text],
input[type=password],
select
{
    height: 2.1em;
    width: 80%;

}
  
textarea {
    height: 9em;
    width: 80%;
}

select {
    height: 100%;
    padding: 0;
    padding-left: 1em;
}

/* input field placeholder text */
input[type=text]::placeholder,
input[type=password]::placeholder,
select::placeholder,
textarea::placeholder
{
    color: var(--darkolive);
}
  
/* submit buttons */
input[type=submit] {
width: 35%;
height: 2.5em;
background-color: rgb(68, 35, 7);
border: 2px solid var(--lightolive);
color: var(--lightolive);
display: flex;
justify-content: center;
align-items: center;
transition: 0.5s;
cursor: pointer;
border-radius:0.45em;
font-size: 17px;
}
  
input[type=submit]:hover {
    transform: scale(1.08);
    color: var(--yellow);
    border: 2px solid var(--yellow);
}

/* 
----------------------------------------------------------------------------------------------------------------
Sign Up Page
----------------------------------------------------------------------------------------------------------------
*/

.signup-form {
    margin-top: -10%;
    height: 40em;
    padding-bottom: 1em;
    background: url('./../assets/images/pitbull_vector.png');
    background-size: 100%;
    background-repeat: no-repeat;

    background-color: rgb(68, 35, 7);
    
}

.signup-form p {
    color: black;
}

.signup-form a {
    color: darkred;
    
}

/* @media only screen and (max-width: 1850px) {
    .signup-form {
        margin-top: -25%;
        background-position-X: 50%;
        background-size: 120%;
    }
} */
@media only screen and (max-width: 1050px) {
    .signup-form {
        margin-top: -60%;
        
    }
}
@media only screen and (max-width: 800px) {
    .signup-form {
        margin-top: -50%;
        
    }
}
@media only screen and (max-width: 435px) {
    .signup-form {
        background-position-X: 50%;
        margin-top: -30%;
        background-position-Y: -10%;
        background-size: 120%;
    }
}
@media only screen and (max-width: 400px) {
    .signup-form {
        margin-top: -10%;
        background-position-Y: 60%;
        /* background-size: 150%; */
    }
}
@media only screen and (max-width: 360px) {
    .signup-form {
        margin-top: 0%;
    }
}
@media only screen and (max-width: 355px) {
    .signup-form {
        margin-top: -20%;
        background-position-Y: 90px;
        background-size: 130%;
    }
}
@media only screen and (max-width: 320px) {
    .signup-form {
        margin-top: 15%;
        background-position-Y: 180px;
        background-size: 120%;
    }
}


/* 

/* 
----------------------------------------------------------------------------------------------------------------
Edit User Page
----------------------------------------------------------------------------------------------------------------
*/

.edit-user-form {
    margin-top: -10%;
    height: 40em;
    padding-bottom: 1em;
    background: url('./../assets/images/pitbull_vector.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: rgb(68, 35, 7);
    
}

.edit-user-form p {
    color: black;
}

@media only screen and (max-width: 1850px) {
    .edit-user-form {
        margin-top: -15%;
        background-position-X: 50%;
        background-size: 120%;
    }
}
@media only screen and (max-width: 600px) {
    .edit-user-form {
        margin-top: -15%;
        background-position-X: 50%;
        background-size: 120%;
        width: 80%;
    }
}


@media only screen and (max-width: 435px) {
    .edit-user-form{
        margin-top: -25%;
        background-position-Y: -10%;
        background-size: 130%;
        width: 90%
    }
}
@media only screen and (max-width: 400px) {
    .edit-user-form {
        margin-top: -40%;
        background-position-Y: 20%;
        background-size: 130%;
        width: 95%
    }
    .edit-user-form input[type=submit] {
        font-size: 15px;
        width: 80%;
    }
}
@media only screen and (max-width: 375px) {
    .edit-user-form {
        margin-top: -10%;
        background-position-Y: 90px;
        background-size: 120%;
        width: 95%
    }
}
@media only screen and (max-width: 355px) {
    .edit-user-form {
        margin-top: -10%;
        background-position-Y:120px;
        background-size: 120%;
      
    }

}
@media only screen and (max-width: 325px) {
    .edit-user-form {
        margin-top: -10%;
        background-position-Y:180px;
        background-size: 120%;
      
    }

}


/* 
----------------------------------------------------------------------------------------------------------------
Login Page
----------------------------------------------------------------------------------------------------------------
*/

.login-form {
    margin-top: -20%;
    height: 25em;
    background: url('./../assets/images/ai_puppy.png');
    background-size: 150%;
    background-color: rgb(185, 154, 127);
    background-position-y: -1.5rem;
    background-position-x: -7.5rem;
    background-repeat: no-repeat;    
}

.login-form h1 {
    color: var(--crimson);
}

.login-form a {
    padding-bottom: 1.5rem;
    color: var(--crimson);
    font-weight:bold;
}

.login-form p {
    color: var(--crimson);
    font-weight:bold;
}

.login-errors,
.signup-errors,
.edit-job-errors,
.edit-user-errors
 {
    background-color: rgb(217, 0, 0);
    padding: 6px;
    border-radius: 10px;
    margin: 6px;
    font-size: 18px;

}

@media only screen and (max-width: 1200px) {
    .login-form { margin-top: -30%; }
}

@media only screen and (max-width: 900px) {
    .login-form { margin-top: -40%; }
}

@media only screen and (max-width: 740px) {
    .login-form { margin-top: -50%; }
}

@media only screen and (max-width: 530px) {
    .login-form { margin-top: -80%; }
}

@media only screen and (max-width: 450px) {
    .login-form { margin-top: -90%; }
    .login-form h1 { color: black; }
}

@media only screen and (max-width: 420px) {
    .login-form {
        margin-top: -80%;
        background-position-x: -5rem;
        background-position-y: 1rem;
    }
}

@media only screen and (max-width: 350px) {
    .login-form {
        background-position-x: -6rem;
        background-position-y: 1rem;
        background-size: 170%;
    }
    .login-errors,
    .signup-errors {
        font-size: 15px;
    }
}

@media only screen and (max-width: 280px) {
    .login-form {
        margin-top: -100%;
        background-position-x: -7rem;
        background-position-y: 0rem;
        background-size: 210%;
    }
}

@media only screen and (max-width: 230px) {
    .login-form {
        margin-top: -120%;
        background-position-x: -8.5rem;
        background-position-y: 1.5rem;
        background-size: 240%;
    }

    .login-form p { font-size: 1rem; }
}


/* 
----------------------------------------------------------------------------------------------------------------
New Job Form
----------------------------------------------------------------------------------------------------------------
*/

.new-job-form {
    height: 50em;
    justify-content: space-between;
    padding-bottom: 2em;
    padding-top: 2em;
    background-size: 190%;
    background: url('./../assets/images/dog_vector2.png');
    background-position-y:90px;
    background-repeat: no-repeat;
    background-size: 230%;
    background-color: rgb(96, 49, 5);
}

.datePicker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
}

.datePicker input {
    background-color: none !important;
    width: 100% !important;
    border: none!important;
    height: 1.5em!important;
}

.date-input {
    height: 2.1em;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    background-color: var(--lightolive);
    border: 2px solid var(--darkolive);
}

.time-container input, .type-container select {
    width: 60% !important
}

.date-input:focus {
    border: 3px solid var(--crimson)!important;
   
}

.date-container, .time-container, .type-container {
    height: 2.1em;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 0.25em;
}

/* media queries */
@media only screen and (max-width: 620px) {
    .login-form, 
    .signup-form, 
    .new-job-form,
    .edit-job-form,
    .application-form 
    {
        width: 90vw;
    }
}

@media only screen and (max-width: 450px) {

    .new-job-form {
        height: 900px;
        margin-top: 200px;
        justify-content: space-between;
            
    }

    .new-job-form input, .new-job-form select {
        padding: 1em auto;
            
    }
    
    .date-container,
    .time-container,
    .type-container
    {
        flex-direction: column;
    }
}



/* 
----------------------------------------------------------------------------------------------------------------
Edit Job Form
----------------------------------------------------------------------------------------------------------------
*/

.edit-job-form {
    height: 50em;
    justify-content: space-between;
    padding-bottom: 2em;
    padding-top: 2em;
    background-size: 190%;
    background: url('./../assets/images/dog_vector2.png');
    background-position-y:90px;
    background-repeat: no-repeat;
    background-size: 230%;
    background-color: rgb(96, 49, 5);
}

.date-input {
    height: 2.1em;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    background-color: var(--lightolive);
    border: 2px solid var(--darkolive);
}

.edit-job-form .time-container input,
.edit-job-form .type-container select,
.edit-job-form .date-input {
    width: 60% !important;
}

.edit-job-form .date-container,
.edit-job-form .time-container,
.edit-job-form .type-container {
    height: 2.1em;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 0.25em;
}

/* media queries */
@media only screen and (max-width: 450px) {

    .edit-job-form  {
        height: 900px;
        margin-top: 200px;
        justify-content: space-between;      
    }

    .edit-job-form  input, .edit-job-form select {
        padding: 1em auto;
    }
    
    .edit-job-form .date-container,
    .edit-job-form .time-container,
    .edit-job-form .type-container
    {
        flex-direction: column;
    }
} 

@media only screen and (max-width: 400px) {
    .edit-job-form input[type="submit"] {
        font-size: 15px;
        width: 60%;
    }
}