/* fonts: .woff files located in assets/fonts folder */

@font-face {
  font-family: 'quicksand';
  src: url('./assets/fonts/quicksand-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'quicksand';
  src: url('./assets/fonts/quicksand-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lilly';
  src: url('./assets/fonts/lilly.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* font variables */
  --font-main: 'quicksand', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --font-editorial: 'Lilly', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
   
  /* colour variables */
  --darkolive: rgb(85, 107, 47);
  --deepolive:  rgb(41, 53, 22);
  --lightolive: rgb(210, 228, 179);
  --yellow: rgb(255, 255, 128);
  --primary: rgb(255, 255, 255);
  --forms: #7f9756;

  --darkolive-shadow: rgba(167, 216, 81, 0.35);
  --darkolive-a: rgba(59, 87, 8, 0.712);
  --crimson: rgb(216, 98, 43);
  --brown: rgb(148, 64, 15);
  --gray-a:  rgba(108, 119, 137, 0.55);
  --gray: #6b7688;


}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-main);
}

body {
  width: 100%;
  height: 100vh;
  background: rgb(209,228,176);
  background:
    linear-gradient(90deg,
      rgba(209,228,176,1) 0%,
      rgba(240,255,212,1) 50%,
      rgba(209,228,176,1) 100%);
}

.App { 
  margin-top: 135px;  /* account for header covering text */
}

.btn, input, select, textarea {
  font-family: var(--font-main);
}

a { text-decoration: none; }

ul, li { list-style: none; }


/* media queries */

@media screen and (max-width: 530px) {

  .App { margin-top: 190px; }

}

@media screen and (max-width: 400px) {

  .App { margin-top: 170px; }

}