.header { /* <header> */
    background-color: var(--darkolive);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 15px var(--darkolive-shadow);
    padding: 0 30px;
    font-size: 1.2rem;
    width: 95%;
    height: 80px;
    border-radius: 10px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    justify-self: start;
    z-index:3;
}

.header .app-title { /* <h1> with nested <i> */
    color: var(--yellow);
    font-family: var(--font-editorial);
}

.header .app-title:hover {
    cursor: pointer;
}

.header .fa-paw {   /* paw icon next to app title <i> */
    font-size: 2.75rem;
    transform: rotate(30deg);
    margin-right: 15px;
}

.nav .nav-list {    /* unordered list with naviation links <ul> */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    list-style: none;
    gap: 5vw;
}

.nav .nav-list a {  /* naviation links - <Link> */
    text-decoration: none;
    color: var(--primary);
    white-space: nowrap;
    padding: 0.7rem 1rem;
}

.nav .nav-list a:hover, .fa-bars:hover, .fa-xmark:hover {
    color: var(--yellow);
    transition: all 0.2s ease-in-out;
}

.mobile-toggle-icons { /* <div> that contains hamburger + close icons */
    display: none;   /* should only display in mobile view */
    padding-left: 5rem;
}

.fa-bars {  /* hamburger icon for mobile menu */
    color: var(--primary);
    white-space: nowrap;
    padding: 0.7rem 1rem;
    font-size: 1.5rem;
}

.fa-xmark { /* close icon for mobile menu */
    color: var(--primary);
    white-space: nowrap;
    padding: 0.7rem 1rem;
    font-size: 2rem;
}


/* media queries */
@media screen and (max-width: 850px) {

    .nav .nav-list {
        background-color: var(--darkolive-a);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: 0 8px 32px 0 var(--darkolive-shadow);
        backdrop-filter: blur(4px);
        border-radius: 10px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .nav .nav-list.active { left: 0%; }

    .mobile-toggle-icons { display: block; }

    .nav .nav-list { padding-top: 6rem; }

    .nav .nav-list a {
       display: block;
       widows: 100%;
       padding: 0.8rem 0;
    }
}

@media screen and (max-width: 545px) {
    .header {
        height: 115px;
        padding: 1rem 2rem;

    }

    .app-title, .header .fa-paw { font-size: 39px; }

    .nav .nav-list { padding-top: 8rem; }
}

@media screen and (max-width: 520px) {

    .header {
        height: 110px;
        padding: 4rem 2rem;

    }

    .app-title, .header .fa-paw{ font-size: 33px; }

    .nav .nav-list { padding-top: 10rem; }
}


@media screen and (max-width: 400px) {

    .header {
        height: 115px;
        padding: 3rem 1rem;

    }
    
    .app-title,  .header .fa-paw { font-size: 1.85rem; }

    .nav .nav-list a { padding: 1rem 0; }

}


@media screen and (max-width: 320px) {
    
    .header {
        height: 115px;
        padding: 1.5rem 1rem;

    }

    .app-title, .header .fa-paw { font-size: 8vw; }
}