/* shared elements across pages */

.page-title {
    text-align: center;
}


/* 
----------------------------------------------------------------------------------------------------------------
Signup Page
----------------------------------------------------------------------------------------------------------------
*/

.signup-page {
    height: 100%;
}



/* 
----------------------------------------------------------------------------------------------------------------
Profile Page
----------------------------------------------------------------------------------------------------------------
*/

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    padding-top: 90px;
}

.profile-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 50%;

}

.profile-content .title {
    font-weight: bold;
    font-size: 20px;
}

 #about-me {
   padding-bottom: 20px;
}

.profile-content p {
    font-size: 19px;
}

@media screen and (max-width: 650px) {
    .profile{
        margin-top: -20%;
    }
    .profile-content {
        width: 80%;
    }
  
  }

/* 
----------------------------------------------------------------------------------------------------------------
Jobs Page
----------------------------------------------------------------------------------------------------------------
*/

.jobs-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.browse-jobs-title,
.my-jobs-title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
    color: var(--brown)
}


.btn-add-job,
.btn-all-jobs,
.btn-cancel,
.btn-delete-account
 {
    font-size: 1rem;
    padding: 2px 6px;
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid var(--brown);
    background-color: transparent;
    transition: 0.5s;

}

.home .btn-add-job {
    padding: 8px;
}


.btn-add-job:hover,
.btn-all-jobs:hover,
.btn-cancel:hover,
.btn-delete-account:hover
{
    transform: scale(1.08);
    cursor: pointer;
}

@media screen and (max-width: 400px) {

    .browse-jobs-title { font-size: 7vw; }
  
}
@media screen and (max-width: 370px) {

    .browse-jobs-title { font-size: 26px; }
  
  }


/* 
----------------------------------------------------------------------------------------------------------------
Dashboard Page
----------------------------------------------------------------------------------------------------------------
*/

.dashboard-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#dashboard-title {
    margin-bottom: 30px;
    color: var(--brown);
    font-size: 40px;
}

.dashboard-grid {
    background-color: rgba(107, 35, 1, 0.861);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%
}

.dog-pc-img,
.dashboard-links {
    align-items: center;
    border-radius: 60px;
    width: 100%;
    border-radius: 10px;
    padding-bottom: 20px;
    padding: 10px;
    transition: .3s;
    flex: 0 0 calc(40% - 20px);
    max-width: calc(40% - 20px); 
}

.dashboard-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 70px;
}

.dog-pc-img{
    flex: 0 0 calc(60% - 20px);
    max-width: calc(60% - 20px);
    padding: 20px;
}

.dog-pc-img2 { display: none; }

.dashboard-links a {
    color: var(--lightolive);
    font-size: 24px;
    display: block;
    text-align: center;
}

.dashboard-links a:hover { color: var(--yellow) }

@media screen and (max-width: 1150px) {

    .dashboard-grid { width: 85% }

    .dashboard-links {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .dashboard-links a { font-size: 22px; }
}

@media screen and (max-width: 840px) {

    .dashboard-links a { padding: 10px; }
    .dog-pc-img2 { width: 100%; }
}

@media screen and (max-width: 840px) {

    .dashboard-grid {
        padding-top: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;   
    }

    .dashboard-links a {
        font-size: 22px;
        width: 200%;
    }

    .dog-pc-img { display: none; }

    .dog-pc-img2 {
        display: flex;
        width: 90%;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .dashboard-links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    
    }

    .dashboard-links a {
        padding: 15px;
        font-size: 22px;
    }
}
    
    @media screen and (max-width: 540px) {
        .dashboard-links a{
            padding: 10px;
            font-size: 20px;
            width: 350%;
        }

        #dashboard-title { font-size: 32px; }
    }

    @media screen and (max-width: 285px) {

        .dashboard-links a { font-size: 16px; }
        #dashboard-title { font-size: 28px; }
    }

    @media screen and (max-width: 250px) {

        .dashboard-links a { width: 480%; }
        #dashboard-title { font-size: 28px; }
    }


/* 
----------------------------------------------------------------------------------------------------------------
Notification Page after logout successful
----------------------------------------------------------------------------------------------------------------
*/




.logout-successful-page{
    margin-top: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}


.logout-successful-grid{
    display: grid;
    grid-template-columns: 40% 60%;
    width: 90%
}

.logout-successful-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logout-successful-text-container h1 {
    margin-bottom: 60px;
    color: var(--deepolive);
    font-size: 47px;
    text-align: center;
    width: 80%;
}


.logout-successful-text-container a {
    color: var(--darkolive);
    font-size: 28px;
    display: block;
    padding: 15px;
    text-align: center;
}


.logout-successful-text-container a:hover {
    color: var(--brown)
}

.logout-successful-img-container {
   
    display: flex;
    align-items: center;
    justify-content: center;

}

.logout-successful-img {
    width: 80%;
    border-radius: 30px;
    margin: 20px;
}


@media screen and (max-width: 1900px) {
    .logout-successful-grid {
        width: 100%;
    } 
    .logout-successful h1{
        font-size: 40px;
        margin: 20px;
        
     } 
     .logout-successful-container a {
        margin: 0;
        
     }
    
}

@media screen and (max-width: 1520px) {
    .logout-successful-text-container h1 { font-size: 35px; } 
    .logout-successful-text-container a { font-size: 25px; }
}

@media screen and (max-width: 1150px) {
    .logout-successful-container h1 { font-size: 32px; } 
    .logout-successful a { font-size: 22px; }
}
@media screen and (max-width: 1050px) {
    .logout-successful-text-container h1 { font-size: 30px; } 
    .logout-successful-container a { font-size: 20px; }
    .logout-successful-page { height: 60vh; }
}
@media screen and (max-width: 985px) {
    .logout-successful-page { height: 50vh; }
}
@media screen and (max-width: 930px) {
    @media screen and (max-width: 985px) {
        .logout-successful-page { height: 70vh; }
        .logout-successful-grid {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
        ;
        }
        .logout-successful-text-container {
            display: flex;
            width: 100%;
            margin: 0;
        }

    }
    .logout-successful-img {
        width: 80%;
        border-radius: 30px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 700px) {
    .logout-successful-page { height: 65vh; }
    .logout-successful-img {
        width: 90%;}
}
@media screen and (max-width: 450px) {
    .logout-successful-text-container h1 { font-size: 25px; } 
    .logout-successful-container a { font-size: 18px; }
    .logout-successful-page { height: 70vh; }
}

@media screen and (max-width: 280px) {
    .logout-successful-page { height: 60vh; }
    .logout-successful-container h1 { font-size: 22px; } 
    .logout-successful-text-container a { font-size: 17px; }

}

/* 
----------------------------------------------------------------------------------------------------------------
Notification Page after account is successfully deleted
----------------------------------------------------------------------------------------------------------------
*/

.account-deleted-page {
    margin-top: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;

}

.account-deleted-grid {
    display: grid;
    grid-template-columns: 55% 45%;
}

.account-deleted-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -80px;
}
.account-deleted-text-container h1 {
    margin-bottom: 60px;
    color: var(--deepolive);
    font-size: 47px;
    text-align: center;
    width: 80%;
}

.account-deleted-text-container a {
    color: var(--darkolive);
    font-size: 28px;
    display: block;
    padding: 15px;
    text-align: center;
}

.account-deleted-text-container a:hover {
    color: var(--brown)
}

.account-deleted-img-container {
    width: 40vw;
    display: flex;
    flex-direction: column;
    background-color: var(--darkolive);
}
.account-deleted-img {
    width: 90%;
    border-radius: 30px;
    margin: 20px;
}

@media screen and (max-width: 1900px) {
    .account-deleted-grid {
        width: 100%;
    } 
    .account-deleted-text-container h1{
        font-size: 40px;
        margin: 20px;
        
     } 
     .account-deleted-text-container a {
        margin: 0;
        
     }
    
}

@media screen and (max-width: 1520px) {
    .account-deleted-text-container h1 { font-size: 35px; } 
    .account-deleted-text-container a { font-size: 25px; }
}

@media screen and (max-width: 1150px) {
    .account-deleted-text-container h1 { font-size: 32px; } 
    .account-deleted-text-container a { font-size: 22px; }
}
@media screen and (max-width: 1050px) {
    .account-deleted-text-container h1 { font-size: 30px; } 
    .account-deleted-text-container a { font-size: 20px; }
    .account-deleted-page { height: 60vh; }
}
@media screen and (max-width: 985px) {
    .account-deleted-page { height: 50vh; }
}
@media screen and (max-width: 930px) {
    @media screen and (max-width: 985px) {
        .account-deleted-page { height: 70vh; }
        .account-deleted-grid {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            gap: 10px;
        ;
        }
        .account-deleted-text-container {
            display: flex;
            width: 100%;
            margin: 0;
        }

    }
    .account-deleted-img {
        width: 80%;
        border-radius: 30px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 700px) {
    .account-deleted-page { height: 65vh; }
    .account-deleted-img {
        width: 90%;}
}
@media screen and (max-width: 450px) {
    .account-deleted-text-container h1 { font-size: 25px; } 
    .account-deleted-text-container a { font-size: 18px; }
    .account-deleted-page { height: 65vh; }
}

@media screen and (max-width: 280px) {
    .account-deleted-page { height: 60vh; }
    .account-deleted-text-container h1 { font-size: 22px; } 
    .account-deleted-text-container a { font-size: 17px; }

}


/* 
----------------------------------------------------------------------------------------------------------------
Home
----------------------------------------------------------------------------------------------------------------
*/

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

  
}

.home-top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
    padding-top: 50px;
    background: url('./../assets/images/dog_cat.jpg');
    background-position: center;

    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    box-shadow: 0 8px 32px 0 var(--darkolive-shadow);

};


#home-welcome-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-about-us, .home-links {
    background-color: var(--deepolive);
    color: white;
    margin-top:5vh;
    margin-bottom:5vh;
    width: 80%;
    border-radius: 10px;
    text-align: left;
    padding: 20px;
  
}

.home-about-us h2, .home-links h2 {
    border-bottom: 1px solid var(--lightolive);
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    color: var(--lightolive);
    font-size: 26px;
}

.home-about-us li,
.home-links li {
    font-size: 18px;
    padding: 10px;
}

.home-about-us span,
.home-links span {
   font-weight: 900;
   color: var(--yellow);
   display: block;
   padding-bottom: 3px;
   font-size: 20px;
   
}

.bunny-link {
    color: var(--lightolive);
}

.img-section-center{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;



}

.home .dog-cat-img-2 {
    width: 80%;
    border-radius: 50%;
    border: 2px solid var(--darkolive);

}

.home .ecs-img,
.home .rabbit-img,
.home .cat-hiding-img
 {
    height: 30vh;
    margin: 10px;
    border-radius: 30px;
    border: 2px solid var(--darkolive)
    
}


/* images */

.home .dog-cat-img {
    width: 25%;
}
.home .kittens-img {
    width: 15%;
}
.home .puppy1-img {
    width: 15%;
}
.home .puppy2-img {
    width: 15%;
}


.pitbull-vector-img {
    width: 8%;
}

@media screen and (max-width: 1600px) {
    .img-section-center {
      width: 90%;
    }
}

@media screen and (max-width: 1050px) {
    .home {
        padding-bottom: 27rem;
    }
}
@media screen and (max-width: 800px) {
    .home {
        padding-bottom: 15rem;
    }
}
@media screen and (max-width: 630px) {

    .home {
        padding-bottom: 0;
    }

    .img-section-center {
      width: 100%;
    }

    .home-about-us {
        margin-top: 50%;
    }
}

@media screen and (max-width: 410px) {
    .img-section-center {
      width: 100%;
    }

    .home-about-us {
        margin-top: 50%;
    }
    .home h2, .home-h2 h2 {
        font-size: 20px;
    }
    .home li {
        font-size: 15px;
    }
}
@media screen and (max-width: 355px) {
    .img-section-center {
      width: 100%;
    }

    .home-about-us {
        margin-top: 60%;
    }
}
@media screen and (max-width: 335px) {


    .home-about-us {
        margin-top: 70%;
    }

    .home h2, .home-h2 h2 {
        font-size: 18px;
    }
    .home li {
        font-size: 13px;
    }
}
@media screen and (max-width: 295px) {


    .home-about-us {
        margin-top: 80%;
    }
}
/* 
----------------------------------------------------------------------------------------------------------------
Job Page
----------------------------------------------------------------------------------------------------------------
*/

.job-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.job-page .btn-all-jobs {
    margin-bottom: 10px;
    color: black;
        padding: 10px;

    
    


}


.btn-add-job:hover {
    transform: scale(1.08);
    cursor: pointer;
}


/* 
----------------------------------------------------------------------------------------------------------------
Delete Account Page
----------------------------------------------------------------------------------------------------------------
*/

.delet-account-page {
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.delet-account-page h1 {
    font-size: 30px;
    color: darkred;
    text-align: center;
}

.delet-account-page input[type=password] {
    width: 20%;
    font-size: 20px;
}

.delete-btn-container {
    display: flex;
    gap: 20px;
}

.delet-account-page .btn-delete-account,
.delet-account-page .btn-cancel {
    margin-bottom: 10px;
    color: black;
    font-size: 20px;
}

@media screen and (max-width: 1550px) {

    .delet-account-page input[type=password] { width: 30%; }
}

@media screen and (max-width: 1000px) {

    .delet-account-page input[type=password] { width: 40%; }
}

@media screen and (max-width: 770px) {

    .delet-account-page h1 { width: 80%; }
}

@media screen and (max-width: 630px) {

    .delet-account-page input[type=password] { width: 60%; }
}

@media screen and (max-width: 425px) {

    .delet-account-page input[type=password] { width: 70%; }

    .delete-btn-container { flex-direction: column; }

    .delet-account-page h1 { font-size: 25px; }

    .delet-account-page .btn-delete-account,
    .delet-account-page .btn-cancel {
        margin-bottom: 8px;
        font-size: 16px;
    }
}


/* 
----------------------------------------------------------------------------------------------------------------
Delete Job Page
----------------------------------------------------------------------------------------------------------------
*/

.delete-job-page {
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.delete-job-page h1 {
    font-size: 30px;
    color: darkred;
    text-align: center;
}

.delete-job-btn-container {
    display: flex;
    gap: 20px;
}

.delete-job-btn {
    font-size: 1rem;
    padding: 2px 6px;
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid var(--brown);
    background-color: transparent;
    transition: 0.5s;
}

.delete-job-btn:hover {
    transform: scale(1.08);
    cursor: pointer;
}

@media screen and (max-width: 770px) {

    .delete-job-page h1 { width: 80%; }
}

@media screen and (max-width: 425px) {

    .delete-job-btn-container { flex-direction: column; }

    .delete-job-page h1 { font-size: 25px; }

    .delete-job-page .delete-job-btn,
    .delete-job-page .btn-cancel {
        margin-bottom: 8px;
        font-size: 16px;
    }
}


/* 
----------------------------------------------------------------------------------------------------------------
404 page
----------------------------------------------------------------------------------------------------------------
*/

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 3rem;
}

.not-found h1 {
    font-family: var(--font-editorial);
    color: var(--darkolive);
    margin-top: 2rem;
}


.rs-gif {
    border-radius: 8px;
    border: 3px solid var(--darkolive);
}

